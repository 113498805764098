<template>
	<el-scrollbar class="seller-scrollbar">
		<el-card class="seller-card">
            <el-breadcrumb slot="header" separator="/">
                <el-breadcrumb-item>{{ $t('seller.customer.operateTitle') }}</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $t('seller.breadcrumb.coupon') }}</el-breadcrumb-item>
            </el-breadcrumb>
			<div class="page-top">
				<div class="name">{{ $t('seller.customer.operateTitle') }}</div>
				<div class="tips">{{ $t('seller.customer.operateTitle') }}</div>
			</div>
			
			<div class="page-step">
				<div class="step-item" :class="{active: activeStep===1}">
					<div class="count">1</div>
					<div class="name">{{ $t('seller.customer.operatSceneText7') }}</div>
				</div>
				<div class="step-line" :class="{active: activeStep===2}"></div>
				<div class="step-item" :class="{active: activeStep===2}">
					<div class="count">2</div>
					<div class="name">{{ $t('seller.customer.operatSceneText8') }}</div>
				</div>
			</div>
			<el-form :model="postForm" label-width="20%" v-if="activeStep === 1">
				<div class="page-section">
					<div class="section-title">{{ $t('seller.customer.operatSceneText9') }}</div>
					<div class="section-wrap">
						<el-form-item :label="$t('seller.screen.title')" style="padding-right: 5%;">
							<el-input v-model="postForm.title" maxlength="18" :placeholder="$t('seller.placeholder.policyTitle')"></el-input>
						</el-form-item>
						<el-form-item style="padding-right: 5%;">
							<div class="tips">{{ $t('seller.customer.operatSceneText10') }}</div>
						</el-form-item>
					</div>
				</div>
				<div class="page-section">
					<div class="section-title">{{ $t('seller.customer.operatSceneText11') }}</div>
					<div class="section-wrap">
						<div class="crowd-list" v-if="crowdInfo">
							<div class="item">
								<div><div class="item-tag">{{ $t('seller.customer.operatSceneText12') }}</div></div>
								<div class="item-main">
									<i class="item-icon"><img src="~@/assets/images/seller/icon-snapshot-3.png" alt=""></i>
									<div class="item-box">
										<div class="name">{{ crowdInfo.title }}</div>
										<div class="text">{{ crowdInfo.des }}</div>
									</div>
								</div>
							</div>
							<div class="item">
								<div><div class="item-tag">{{ $t('seller.customer.operatSceneText13') }}</div></div>
								<div class="item-main">
									<div class="count">{{ crowdInfo.predicts || '-' }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="page-section">
					<div class="section-title">{{ $t('seller.dialogTitle.coupon') }}</div>
					<div class="section-wrap">
						<div class="coupon-top">
							<div class="name">{{ $t('seller.tableHead.crowdCoupon') }}</div>
							<router-link :to="{ name: 'couponCreate',query: { channel: 1 }}"><el-button type="primary" size="mini">{{ $t('seller.actions.addCoupon') }}</el-button></router-link>
						</div>
						<div class="coupon-list">
							<table v-loading="listLoading" width="100%">
								<thead>
									<tr>
										<th width="50px"></th>
										<th>{{ $t('seller.tableHead.crowdCoupon') }}</th>
										<th>{{ $t('seller.tableHead.limitedCopies') }}</th>
										<th>{{ $t('seller.tableHead.stockNum') }}</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="item in couponList" :key="item.coupon_id">
										<td><el-radio v-model="postForm.coupon_id" :label="item.coupon_id">{{ }}</el-radio></td>
										<td>
											<div class="item">
												<div class="item-head">{{ item.name }}</div>
												<div class="item-main">
													<div class="quato">￥<span class="price">{{ item.money }}</span>{{ $t('seller.customer.operatSceneText14') }}</div>
													<div class="text">{{ $t('seller.customer.operatSceneText15') }}{{ item.quota }}{{ $t('seller.customer.operatSceneText16') }}</div>
													<div class="time">{{ $t('seller.tableHead.validity') }}：{{ item.use_begin_time | parseTime('{y}-{m}-{d} {h}:{i}') }} - {{ item.use_end_time | parseTime('{y}-{m}-{d} {h}:{i}') }}</div>
													<div class="btn">{{ $t('seller.actions.collectNow') }}</div>
												</div>
											</div>
										</td>
										<td>{{ item.limit_give_num }}</td>
										<td>{{ item.issuable_num }}</td>
									</tr>
								</tbody>
							</table>
							<pagination v-show="couponTotal > 0" :total="couponTotal" :page.sync="couponCurr" :limit.sync="couponSize" @pagination="getCouponList"></pagination>
						</div>
					</div>
				</div>
				<div class="page-bottom">
					<el-button round @click="$router.push({ name: 'customerNonstandard' })">{{ $t('seller.actions.cancel') }}</el-button>
					<el-button type="primary" round @click="handleNext">{{ $t('seller.actions.nextStep') }}</el-button>
				</div>
			</el-form>
			<div class="page-steps-two"  v-else-if="activeStep === 2">
				<div class="page-extend">
					<el-checkbox v-model="postForm.is_recommend" :true-label="1" :false-label="0"  @change="handleChangePush"></el-checkbox>
					<div class="extend-main">
						<div class="title">{{ $t('seller.customer.operatSceneText17') }}</div>
						<div class="tips">{{ $t('seller.customer.operatSceneText18') }}</div>
						<div class="subtitle">{{ $t('seller.customer.operatSceneText19') }}</div>
						<div class="extend-box">
							<el-form ref="pushForm" :model="pushForm" :rules="pushRules" label-width="90px" size="small">
								<el-form-item :label="$t('seller.campaign.activityTime')" prop="time_from">
									<el-date-picker
										v-model="pushTimes"
										type="datetimerange"
										:range-separator="$t('seller.unit.to')"
										:start-placeholder="$t('seller.placeholder.startDate')"
										:end-placeholder="$t('seller.placeholder.endDate')"
										value-format="timestamp"
										:picker-options="pickerOptions"
										@change="handleChangePickerPush"
										style="width: 380px">
									</el-date-picker>
								</el-form-item>
							</el-form>
						</div>
					</div>
				</div>
				<div class="page-extend">
					<el-checkbox v-model="postForm.is_poster" :true-label="1" :false-label="0" @change="handleChangePoster"></el-checkbox>
					<div class="extend-main">
						<div class="title">{{ $t('seller.customer.operatSceneText20') }}</div>
						<div class="tips">{{ $t('seller.customer.operatSceneText21') }}</div>
						<div class="subtitle">{{ $t('seller.customer.operatSceneText19') }}</div>
						<div class="extend-box">
							<el-form ref="posterForm" :model="posterForm" :rules="posterRules" label-width="90px" size="small">
								<el-form-item :label="$t('seller.tableHead.posterEditor')" prop="banner" ref="poster_banner">
									<div class="item-upload" v-if="posterForm.banner">
										<img :src="posterForm.banner" alt="">
										<div class="btn-delete" @click="handleDeleteBanner">{{ $t('seller.actions.delete') }}</div>
									</div>
									<div v-else class="item-upload" @click="handleShowUpload">
										<i class="el-icon-plus"></i>
									</div>
									<el-dialog
										:title="$t('seller.dialogTitle.image')"
										custom-class="seller-dialog"
										width="960px"
										:close-on-click-modal="false"
										:close-on-press-escape="false"
										:visible.sync="dialogVisibleUpload"
										:before-close="handleCloseUpload">
										<upload-material
											ref="uploadImage"
											:key="cropperRandom"
											@get-uplod-action="handleUploadAction"
											:upload-type="true"
											:upload-action="'cropping'"
											:cropper-scale="cropperScale"
											:min-width="640">
										</upload-material>
									</el-dialog>
								</el-form-item>
								<el-form-item :label="$t('seller.formItem.jumpLink')" prop="link_type">
									<el-radio-group v-model="posterForm.link_type">
										<el-radio label="1" :disabled="!couponList.length > 0">{{ $t('seller.customer.operatSceneText22') }}</el-radio>
										<el-radio label="2">{{ $t('seller.customer.operatSceneText23') }}<span style="margin-left: 10px;"><el-input v-model="posterForm.link_url" style="width: 200px;"></el-input></span></el-radio>
									</el-radio-group>
								</el-form-item>
								<el-form-item :label="$t('seller.campaign.activityTime')" prop="time_from">
									<el-date-picker
										v-model="posterTimes"
										type="datetimerange"
										:range-separator="$t('seller.unit.to')"
										:start-placeholder="$t('seller.placeholder.startDate')"
										:end-placeholder="$t('seller.placeholder.endDate')"
										value-format="timestamp"
										:picker-options="pickerOptions"
										@change="handleChangePickerPoster"
										style="width: 380px">
									</el-date-picker>
								</el-form-item>
							</el-form>
						</div>
					</div>
				</div>
				<div class="page-bottom">
					<el-button round @click="handlePrev">{{ $t('seller.actions.backStep') }}</el-button>
					<el-button type="primary" round @click="handleSave" :disabled="!(postForm.is_poster ===1 || postForm.is_recommend ===1)">{{ $t('seller.actions.onekeyPromote') }}</el-button>
				</div>
			</div>
		</el-card>
	</el-scrollbar>
</template>

<script>
import { fetchCustomCrowdInfo, fetchCustomCoupon, createCustomOperate } from "@/api/seller/customer"
import uploadMaterial from '@/components/seller/uploadMaterial'
import Pagination from '@/components/seller/pagination'
export default {
	components: {
		uploadMaterial,
		Pagination
	},
	data() {
		var that = this
		return {
			activeStep: 1,
			crowdInfo: {},
			listLoading: false,
			couponList: [],
			couponCurr: 1,
			couponSize: 5,
			couponTotal: 0,
			postForm: {
				title: '',
				coupon_id: '',
				is_poster: 0,
				is_recommend: 0,
				type: 1
			},
			posterForm: {
				banner: '',
				link_type: '1',
				link_url: '',
				time_from: '',
				time_to: ''
			},
			posterRules: {
				banner: [{ required: true, message: that.$t('seller.validator.posterPic'), trigger: 'blur' }],
				link_type: [{ required: true, message: that.$t('seller.validator.jumpLink') }],
				time_from: [{ required: true, message: that.$t('seller.validator.activityTime') }]
			},
			posterTimes: '',
			pushForm: {
				time_from: '',
				time_to: ''
			},
			pushRules: {
				time_from: [{ required: true, message: that.$t('seller.validator.activityTime') }]
			},
			pushTimes: '',
			pickerOptions: {
				disabledDate(time) {
					return time.getTime()<Date.now()-86400000;
				},
			},
			dialogVisibleUpload: false,
			cropperRandom: Math.random(),
			cropperScale: [320,107]
		}
	},
	created () {
		this.getData()
		this.getCouponList()
	},
	methods: {
		async getData() {
			if(this.$route.query.crowd_id) {
				const { data } = await fetchCustomCrowdInfo({crowd_id: this.$route.query.crowd_id})
				this.crowdInfo = data
			}
		},
		getCouponList() {
			this.listLoading = true
			const params = {
				page_index: (this.couponCurr - 1) *this.couponSize,
				page_size: this.couponSize
			}
			fetchCustomCoupon(params).then(response => {
				const { data } = response
				this.couponList = data.list
				this.couponTotal = data.count || 0
				this.listLoading = false
			})
		},
		handleNext() {
			if(this.postForm.title === '') {
				this.$message.error(this.$t('seller.placeholder.policyTitle'))
				return false
			}
			if(this.postForm.coupon_id === '') {
				this.$message.error(this.$t('seller.errorMsg.policyCoupon'))
				return false
			}
			this.activeStep = 2
		},
		handleChangePush(val) {
			this.$refs.pushForm.clearValidate()
		},
		handleChangePoster(val) {
			this.$refs.posterForm.clearValidate()
		},
		handleChangePickerPush(times) {
			if(times) {
				this.pushForm.time_from = parseInt(times[0] / 1000)
				this.pushForm.time_to = parseInt(times[1] / 1000)
			} else {
				this.pushForm.time_from = ''
				this.pushForm.time_to = ''
			}
		},
		handleChangePickerPoster(times) {
			if(times) {
				this.posterForm.time_from = parseInt(times[0] / 1000)
				this.posterForm.time_to = parseInt(times[1] / 1000)
			} else {
				this.posterForm.time_from = ''
				this.posterForm.time_to = ''
			}
		},
		handleDeleteBanner() {
			this.posterForm.banner = ''
		},
		handleShowUpload() {
			this.cropperRandom = Math.random()
			this.dialogVisibleUpload = true
		},
		handleCloseUpload() {
			this.dialogVisibleUpload = false
		},
		handleUploadAction(data) {
			if(data.type === 1) {
				this.posterForm.banner = data.img_url
				this.$refs.poster_banner.clearValidate()
			}
			this.dialogVisibleUpload = false
		},
		handlePrev() {
			this.activeStep = 1
		},
		handleSave() {
			var falg = false
			var msgText = []
			if(this.postForm.is_poster === 1) {
				msgText.push(this.$t('seller.errorMsg.operateText1'))
				this.$refs.posterForm.validate(valid => {
					if(!valid) {
						falg = true
						return false
					}
				})
			}
			if(this.postForm.is_recommend === 1) {
				msgText.push(this.$t('seller.errorMsg.operateText2'))
				this.$refs.pushForm.validate(valid => {
					if(!valid) {
						falg = true
						return false
					}
				})
			}
			if(falg) {
				return false
			}
			const params = {
				...this.postForm,
				crowd_id: this.crowdInfo.crowd_id,
				poster_content: JSON.stringify(this.posterForm),
				recommend_content: JSON.stringify(this.pushForm)
			}
			createCustomOperate(params).then(() => {
				this.$confirm(msgText.join('、')+this.$t('seller.errorMsg.operateText3'), this.$t('seller.confirm.title'), {
					confirmButtonText: this.$t('seller.actions.confirm'),
					type: 'success'
				}).then(() => {
					this.$router.push({name:'customerNonstandard'})
				})
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.seller-card {
	.page-top {
		margin-bottom: 24px;
		.name {
			font-size: 20px;
			margin-bottom: 8px;
		}
		.tips {
			font-size: 14px;
			color: #999;
		}
	}
	.page-step {
		width: 800px;
		margin: 32px auto 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		.step-item {
			flex-shrink: 0;
			display: flex;
			.count {
				flex-shrink: 0;
				font-size: 14px;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				line-height: 30px;
				text-align: center;
				color: #666;
				background-color: #f2f4f7;
				margin: 0 12px;
			}
			.name {
				flex-shrink: 0;
				max-width: 75px;
				font-size: 14px;
				margin-top: 8px;
				padding-right: 12px;
				box-sizing: border-box;
			}
			&.active {
				.count {
					color: #fff;
					background-color: $--seller-primary;
				}
			}
		}
		.step-line {
			width: calc(50% - 129px);
			height: 2px;
			background-color: #f0f2f5;
			&.active {
				background-color: $--seller-primary;
			}
		}
	}
	.page-section {
		width: 80%;
		border-radius: 6px;
		padding-bottom: 24px;
		margin: 0 auto 18px;
		.section-title {
			padding: 0 20px;
			height: 42px;
			line-height: 42px;
			margin-bottom: 18px;
			font-size: 14px;
			color: #333;
			border-bottom: 1px solid #f3f3f3;
		}
		.section-wrap {
			.tips {
				line-height: 1.28571;
				font-size: 12px;
				color: rgb(153, 153, 153);
			}
			.crowd-list {
				display: flex;
				.item {
					flex: 1;
					height: 100px;
					border-radius: 12px;
					margin-right: 20px;
					padding-bottom: 10px;
					position: relative;
					display: flex;
					flex-direction: column;
					background-color: #f5f9ff;
					.item-tag {
						display: inline-block;
						font-size: 14px;
						padding: 6px 12px;
						border-top-left-radius: 12px;
						border-bottom-right-radius: 12px;
						background-color: #d7e7ff;
						color: #3386f1;
					}
					.item-main {
						flex: 1;
						padding: 0 24px;
						display: flex;
						align-items: center;
						.item-box {
							flex: 1;
							width: 0;
							margin-left: 14px;
							.name {
								font-size: 14px;
								font-weight: 600;
								color: #333;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}
							.text {
								color: #999;
								font-size: 12px;
								margin-top: 6px;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
							}

						}
						.count {
							color: #111;
							font-size: 32px;
						}
						.item-icon {
							flex-shrink: 0;
							width: 40px;
							height: 40px;
							display: flex;
							align-items: center;
							justify-content: center;
							border-radius: 50%;
							background-color: $--seller-primary;
							img {
								width: 21px;
								height: 26px;
							}
						}
					}
					&:last-child {
						margin-right: 0;
					}
				}
			}
			.coupon-top {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 28px;
				.name {
					font-size: 14px;
					color: #666;
					padding: 12px 0;
				}
			}
			.coupon-list {
				padding: 10px 24px;
				border-radius: 4px;
				background: #f7faff;
				table {
					border-spacing: 0 12px;
					th {
						color: #999;
						padding: 12px 18px;
					}
					td {
						padding: 18px;
					}
					tbody {
						tr {
							background-color: #e8f0ff;
						}
					}
				}
				.item {
					width: 350px;
					padding: 12px;
					box-sizing: border-box;
					border-radius: 6px;
					background-color: #fff;
					.item-head {
						color: #333;
						font-size: 14px;
						margin-bottom: 8px;
					}
					.item-main {
						padding: 0 18px 10px;
						border-radius: 6px;
						color: #ff5000;
						position: relative;
						background-color: #ffe8e8;
						.quato {
							font-size: 12px;
							font-weight: 600;
							.price {
								font-size: 25px;
								margin: 0 5px;
							}
						}
						.text {
							font-size: 12px;
							font-weight: 600;
						}
						.time {
							font-size: 12px;
							margin-top: 8px;
						}
						.btn {
							top: 30px;
							right: 18px;
							font-weight: 600;
							font-size: 15px;
							position: absolute;
						}
					}
				}
			}
		}
	}
	.page-extend {
		width: 60vw;
		padding: 24px 24px 24px 22px;
		margin: 0 auto 18px;
		background-color: #f7faff;
		border-radius: 3px;
		display: flex;
		.extend-main {
			padding-left: 18px;
			flex: 1;
			width: 0;
			.title {
				height: 25px;
				line-height: 25px;
				margin-bottom: 12px;
				font-weight: 600;
				font-size: 14px;
				color: #333;
			}
			.tips {
				color: #666;
				font-size: 14px;
				margin-bottom: 24px;
			}
			.subtitle {
				color: #999;
				font-size: 14px;
				margin-bottom: 12px;
			}
			.extend-box {
				padding: 24px;
				border-radius: 3px;
				background-color: #fff;
				.item-upload {
					width: 320px;
					height: 107px;
					display: flex;
					cursor: pointer;
					align-items: center;
					justify-content: center;
					border: 1px dashed #cbcbcb;
					position: relative;
					[class^=el-icon-] {
						font-size: 32px;
						color: $--seller-primary;
					}
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
					.btn-delete {
						bottom: 0;
						left: 0;
						width: 100%;
						color: #fff;
						cursor: pointer;
						height: 24px;
						line-height: 24px;
						font-size: 12px;
						position: absolute;
						text-align: center;
						background: rgba(57,120,247,.9);
					}
				}
			}
		}
	}
	.page-bottom {
		text-align: center;
	}
}
</style>